import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Ref, Vue } from 'vue-property-decorator'
import GrtSecurityLayout from '../../../layouts/security/security.layout.vue'

@Component({
  name: 'GtrResetPasswordView'
})
export default class GtrResetPasswordView extends Vue {
    @Ref()
    readonly observerResetPasswordForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        reset: {
          email: null
        }
      }
    }

    created () {
      this.$emit('update:layout', GrtSecurityLayout)
    }

    async submit () {
      if (!this.$data.submitting) {
        try {
          this.$data.submitting = true
          this.$data.reset.admin_base_url = window.location.origin
          await this.$store.dispatch('security/resetPassword', this.$data.reset)
          Container.get(GtrStorage).setItem('reset_email', this.$data.reset.email)
          this.$router.push({ name: 'security.check-email' })
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
          if (error.data.error_message) {
            const record: Record<string, string[]> = {
              email: [error.data.error_message]
            }
            this.showServerErrors(record)
          }
        } finally {
          this.$data.reset = {
            email: null
          }
          this.observerResetPasswordForm.reset()
          this.$data.submitting = false
        }
      }
    }

    private showServerErrors (errors: Record<string, string | string[]>): void {
      this.observerResetPasswordForm.setErrors(errors)
    }
}
